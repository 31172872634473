<template>
  <div>
      <contactForm :contactId="contactId"></contactForm>
  </div>
</template>

<script>
import contactForm from '@/components/contacts/contact/form.vue'

export default {
  data () {
    return {
      contactId: null
    }
  },
  components: { contactForm },
  created(){
    this.getView()
    if (this.$route.query.contactId != null){
      this.contactId = this.$route.query.contactId
    }
  },
  methods: {
    getView() {
        this.$http.get('/ViewAccess/contact')
        .then(() => {
        })
        .catch(() => {
        })
      },
  }
}
</script>

<style>

</style>