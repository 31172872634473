<template>
    <div class="row g-gs">
        <div class="col-lg-12">
            <div class="card card-bordered h-100">
                <div class="card-inner">
                    <button  v-on:click="saveContact()" class="btn btn-sm btn-success float-right"><em class="icon ni ni-save mr-1"></em> Save</button>
                    <h5 class="card-title"><em class="icon ni ni-user"/> Contact Details</h5>
                    <div class="row">
                        <div class="col-md-6">
                            <div class="row g-3 align-center">
                                <div class="col-lg-5">
                                    <div class="form-group">
                                        <span class="form-label float-right" for="contactOwner"><em class="icon ni ni-user"/> Contact Owner</span>
                                    </div>
                                </div>
                                <div class="col-lg-7">
                                    <div class="form-group">
                                        <div class="form-control-wrap">
                                            <a-select class="invisibox" v-model="contact.contactOwnerUserId"
                                            show-search
                                            placeholder="Select a user"
                                            option-filter-prop="children"
                                            :filter-option="filterOption"
                                            style="width: 100%">
                                                <a-select-option :value="null" > Not selected </a-select-option> 
                                                <a-select-option v-for="user in users" v-bind:key="user.id">
                                                    {{ user.firstName + ' ' + user.lastName }}
                                                </a-select-option>
                                            </a-select>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="row g-3 align-center">
                                <div class="col-lg-5">
                                    <div class="form-group">
                                        <span class="form-label float-right"><em class="icon ni ni-user"/> First Name</span>
                                    </div>
                                </div>
                                <div class="col-lg-7">
                                    <div class="form-group">
                                        <div class="form-control-wrap">
                                            <a-input placeholder="First Name" v-model="contact.firstName" :class="{'is-error' : $v.contact.firstName.$invalid }" type="text" class="form-control invisibox"/>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="row g-3 align-center">
                                <div class="col-lg-5">
                                    <div class="form-group">
                                        <label class="form-label float-right"><em class="icon ni ni-user"/> Last Name</label>
                                    </div>
                                </div>
                                <div class="col-lg-7">
                                    <div class="form-group">
                                        <div class="form-control-wrap">
                                            <a-input placeholder="Last Name" v-model="contact.lastName" :class="{'is-error' : $v.contact.lastName.$invalid }" type="text" class="form-control invisibox"/>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="row g-3 align-center">
                                <div class="col-lg-5">
                                    <div class="form-group">
                                        <label class="form-label float-right" for="jobTitle"><em class="icon ni ni-user"/> Job Title</label>
                                    </div>
                                </div>
                                <div class="col-lg-7">
                                    <div class="form-group">
                                        <div class="form-control-wrap">
                                            <a-input placeholder="Job Title" v-model="contact.jobTitle" type="text" class="form-control invisibox"/>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="row g-3 align-center">
                                <div class="col-lg-5">
                                    <div class="form-group">
                                        <label class="form-label float-right"><em class="icon ni ni-notes-alt"/> Contact Type</label>
                                    </div>
                                </div>
                                <div class="col-lg-7">
                                    <div class="form-group">
                                        <div class="form-control-wrap">
                                            <a-select placeholder="Select Contact Type" class="invisibox" v-model="contact.contactTypeId" style="width: 100%">
                                            <a-select-option :value="null" > Not selected </a-select-option> 
                                            <a-select-option v-for="contactType in contactTypes" v-bind:key="contactType.id">
                                                {{ contactType.text }}
                                            </a-select-option>
                                        </a-select>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="row g-3 align-center">
                                <div class="col-lg-5">
                                    <div class="form-group">
                                        <label class="form-label float-right"><em class="icon ni ni-mail"/> Email Opt Out</label>
                                    </div>
                                </div>
                                <div class="col-lg-7">
                                    <div class="form-group">
                                        <div class="form-control-wrap">
                                            <a-checkbox v-model="contact.emailOptOut" style="margin-left: 10px"></a-checkbox>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="row g-3 align-center">
                                <div class="col-lg-5">
                                    <div class="form-group">
                                        <label class="form-label float-right" for="phone"><em class="icon ni ni-call"/> Phone</label>
                                    </div>
                                </div>
                                <div class="col-lg-7">
                                    <div class="form-group">
                                        <div class="form-control-wrap">
                                            <a-input placeholder="Phone" v-model="contact.phoneNumber" type="text" class="invisibox" />
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="row g-3 align-center">
                                <div class="col-lg-5">
                                    <div class="form-group">
                                            <label class="form-label float-right" for="mobile"><em class="icon ni ni-mobile"/> Mobile</label>
                                    </div>
                                </div>
                                <div class="col-lg-7">
                                    <div class="form-group">
                                        <div class="form-control-wrap">
                                            <a-input placeholder="Mobile" v-model="contact.mobile" type="text" class="invisibox" />
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="row g-3 align-center">
                                <div class="col-lg-5">
                                    <div class="form-group">
                                        <label class="form-label float-right" for="fax"><em class="icon ni ni-printer"/> Fax</label>
                                    </div>
                                </div>
                                <div class="col-lg-7">
                                    <div class="form-group">
                                        <div class="form-control-wrap">
                                            <a-input placeholder="Fax" v-model="contact.fax" class="invisibox" id="fax"/>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="row g-3 align-center">
                                <div class="col-lg-5">
                                    <div class="form-group">
                                        <label class="form-label float-right" for="email"><em class="icon ni ni-mail"/> Email</label>
                                    </div>
                                </div>
                                <div class="col-lg-7">
                                    <div class="form-group">
                                        <div class="form-control-wrap">
                                            <a-input placeholder="Email" v-model="contact.email" type="text" class="form-control invisibox" id="email"/>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="row g-3 align-center">
                                <div class="col-lg-5">
                                    <div class="form-group">
                                        <label class="form-label float-right"><em class="icon ni ni-globe"/> Lead Source</label>
                                    </div>
                                </div>
                                <div class="col-lg-7">
                                    <div class="form-group">
                                        <div class="form-control-wrap">
                                            <a-select class="invisibox" :default-value="{ key: 0 }" v-model="contact.leadSourceId" show-search placeholder="Select a Lead" option-filter-prop="children" :filter-option="filterOption" style="width: 100%">
                                                <a-select-option key="0" > Not selected </a-select-option> 
                                                <a-select-option v-for="user in users" v-bind:key="user.id">
                                                    {{ user.firstName + ' ' + user.lastName }}
                                                </a-select-option>
                                            </a-select>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-md-6">
                            <div v-if="!contact.id">
                                <h6>Please save contact and then you can assign accounts.</h6>
                            </div>
                            <div class="col-md-12" v-if="contact.id">
                                <h6>Accounts Assigned To</h6>
                                <div class="row">
                                    <div class="col-3">
                                        <div class="form-group">
                                            <label class="form-label float-right" for="accountName"><em class="icon ni ni-building"/> Assign To Account</label>
                                        </div>
                                    </div>
                                    <div class="col-lg-7">
                                        <div class="form-group">
                                            <div class="form-control-wrap">
                                                <a-select class="invisibox" v-model="contact.accountId" @change="assignContactToAccount" show-search placeholder="Select a Account" option-filter-prop="children" :filter-option="filterOption" style="width: 100%">
                                                    <a-select-option :value="0">Select an Account</a-select-option>
                                                    <a-select-option v-for="a in accounts" :key="a.id">
                                                        {{ a.text }}
                                                    </a-select-option>
                                                </a-select>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col-11">
                                        <table class="table">
                                            <thead>
                                                <th>Account Name</th>
                                                <th class="text-center">Action</th>
                                            </thead>
                                            <tbody>
                                                <tr v-for="ca in contactAccounts" :key="ca.id">
                                                    <td>{{ ca.accountName }}</td>
                                                    <td class="text-center"><a><em class="icon ni ni-cross text-danger" @click="removeAccountFromContact(ca)" style="fontSize: 18px;"/></a></td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                            <div class="col-md-12" v-if="contact.id" style="padding-top: 30px">
                                <h6>Contact Authorisation Access</h6>
                                <div class="row">
                                    <div class="col-3">
                                        <div class="form-group">
                                            <label class="form-label float-right" for="accountName"><em class="icon ni ni-notes-alt"/> Assign Auth Access</label>
                                        </div>
                                    </div>
                                    <div class="col-lg-7">
                                        <div class="form-group">
                                            <div class="form-control-wrap">
                                                <a-select placeholder="Select Auth Access" class="invisibox" @change="assignAuthAccessToContact" show-search v-model="contact.authorisationAccessId" style="width: 100%">
                                                    <a-select-option :value="null" > Not selected </a-select-option> 
                                                    <a-select-option v-for="authorisationAccess in authorisationAccesses" v-bind:key="authorisationAccess.id">
                                                        {{ authorisationAccess.text }}
                                                    </a-select-option>
                                                </a-select>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col-11">
                                        <table class="table">
                                            <thead>
                                                <th>Auth Access</th>
                                                <th class="text-center">Action</th>
                                            </thead>
                                            <tbody>
                                                <tr v-for="ca in contactAuthAccess" :key="ca.id">
                                                    <td>{{ ca.name }}</td>
                                                    <td class="text-center"><a><em class="icon ni ni-cross text-danger" @click="removeAuthAccessFromContact(ca)" style="fontSize: 18px;"/></a></td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="row">
                        
                    </div>
                    <div class="row">
                        <div class="col-12">
                            <button v-on:click="saveContact()" class="btn btn-sm btn-success float-right"><em class="icon ni ni-save mr-1"></em> Save</button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { required } from 'vuelidate/lib/validators'

export default {
    props: {
        contactId: Number,
    },
    validations: {
        contact: {
            firstName: {
                required
            },
            lastName: {
                required
            }
        }
    },
    data(){
        return {
            contact: {
                accountId: 0
            },
            dateFormat: 'DD/MM/YYYY',
            contactTypes: [],
            authorisationAccesses: [],
            accounts: [],
            filterOption: '',
            users: [],
            contacts: [],
            contactAccounts: [],
            contactAuthAccess: []
        }
    },
    created() {
        this.getLists()
        if (this.contactId != null) {
            this.getContact(this.contactId)
            this.getContactAccounts(this.contactId)
            this.getContactAuthAccess(this.contactId)
        } else {
            this.getContact()
        }
    },
    methods: {
        getContact(id) {
            let uri  =  id ? ('/contacts/get_Contact/' + id) : '/contacts/get_Contact/'
            this.$http.get(uri)
            .then((response) => {
                this.contact = response.data
                this.contact.accountId = 0 // so it shows the default value. It keeps being overidden. Using null doesn't work
            })
        },
        assignContactToAccount() {
            this.$http.post('/contacts/Assign_ContactToAccount', this.contact)
            .then(() => {
                this.getContactAccounts(this.contact.id)
                this.$message.success('Contact Assigned To Account')
            })
            .catch(() => {
                this.$message.error('There has been an error')
            })
        },
        getContactAccounts(id) {
            this.$http.get('/contacts/get_ContactAccounts/' + id)
            .then((response) => {
                this.contactAccounts = response.data
            })
            .catch(() => {
                this.$message.error('There has been an error')
            })
        },
        getContactAuthAccess(id) {
            this.$http.get('/contacts/get_ContactAuthAccess/' + id)
            .then((response) => {
                this.contactAuthAccess = response.data
            })
            .catch(() => {
                this.$message.error('There has been an error')
            })
        },
        saveContact () {
            if (this.contact.id != null) {
                if (this.$v.contact.$invalid) {
                    this.$message.error('Please check for any form errors.')
                } else {
                    this.updateContact()
                }
            } 
            else {
                if (this.$v.contact.$invalid) {
                    this.$message.error('Please check for any form errors.')
                } else {
                    this.saveNewContact()
                }
            }
        },
        saveNewContact() {
            this.$http.post('/contacts/Add_Contact', this.contact)
            .then((response) => {
                this.$message.success('Contact Created')
                this.contact = response.data
            })
            .catch(() => {
                this.$message.error('There has been an error')
            })
        },
        updateContact() {
            this.$http.post('/contacts/Update_Contact', this.contact)
            .then(() => {
                this.$message.success('Contact Updated')
            })
            .catch(() => {
                this.$message.error('There has been an error')
            })
        },
        getLists(){
            this.$http.get('/lists/Get_Users')
            .then((response) => {
                this.users = response.data
            })
            .catch(() => {
            this.$message.error('There has been an error')
            })
            this.$http.get('/lists/Get_List/contactTypes')
            .then((response) => {
                this.contactTypes = response.data
            })
            .catch(() => {
            this.$message.error('There has been an error')
            })
            this.$http.get('/lists/Get_List/authorisationAccess')
            .then((response) => {
                this.authorisationAccesses = response.data
            })
            .catch(() => {
            this.$message.error('There has been an error')
            })
            this.$http.get('/lists/Get_List/accounts')
            .then((response) => {
                this.accounts = response.data
            })
            .catch(() => {
            this.$message.error('There has been an error')
            })
        },
        assignAuthAccessToContact(){
            this.$http.post('/contacts/Assign_AuthAccessToContact', this.contact)
            .then(() => {
                this.getContactAuthAccess(this.contact.id)
                this.$message.success('Auth Access Assigned To Contact')
            })
            .catch(() => {
            this.$message.error('There has been an error')
            }) 
        },
        removeAccountFromContact(contactAccount){
            this.$http.post('/contacts/Remove_AccountFromContact', contactAccount)
            .then(() => {
                this.getContactAccounts(this.contact.id)
                this.$message.success('Account Removed From Contact')
            })
            .catch(() => {
            this.$message.error('There has been an error')
            }) 
        },
        removeAuthAccessFromContact(contactAuth){
            this.$http.post('/contacts/Remove_AuthAccessFromContact', contactAuth)
            .then(() => {
                this.getContactAuthAccess(this.contact.id)
                this.$message.success('Auth Access Removed From Contact')
            })
            .catch(() => {
            this.$message.error('There has been an error')
            }) 
        }
    }
}
</script>

<style scoped>
.form-label {
    margin-top: 7px
}
</style>